<template src="./EditSampleManagementPage.html"></template>
<style scoped lang="scss" src="./EditSampleManagementPage.scss"></style>

<script>
import {OrderType} from "../../../../../constants";
import { OrderEditMixin } from '../../../../../mixins'

const EditSampleManagementForm = () => import('@/components/form/mass-management-form/MassManagementForm')

export default {
  name: 'EditMassManagementPage',
  mixins: [OrderEditMixin],
  components: { EditSampleManagementForm },
  data: function () {
    return {
      orderType: OrderType.Sample
    }
  },
  methods: {
    handleOnFormCancel() {
      this.$router.push({ name: 'routes.sampleManagement' })
    }
  }
}
</script>
